import React from "react"

import Layout from "../theme/layout"
import SEO from "../components/header/seo"
import Register from "../components/account/register-panel"

const RegisterPage = ({ location }) => {
  return (
    <Layout location={location}>
      <SEO title="Register" location={location} />
      <Register location={location} />
    </Layout>
  )
}

export default RegisterPage