import React, { useState, useContext } from "react"
import { navigate } from "gatsby"
import styled from "styled-components"

import { Formik, Form } from "formik"
import Reaptcha from "reaptcha"

import AuthContext from "../../context/auth/auth-context"
import CartContext from "../../context/cart/cart-context"

import BlurLoader from "../generic/loaders/blur-loader"
import LinkWrapper from "../../lib/link-wrapper"

import AccountsFormInputField from "./accounts-form-input-field"
import AccountMetaFields from "./address/account-meta-fields"
import ResponsiveButton from "./responsive-button"
import { analyticsRegister } from "../../services/google-analytics"
import { pixelCompleteRegistration } from "../../services/facebook-pixel"

// ================
// 	  COMPONENT
// ================

const Register = () => {
  const { performRegister, loggedInState } = useContext(AuthContext)
  const { getCart } = useContext(CartContext)

  const [customerType, setCustomerType] = useState("individual")
  const [loading, setLoading] = useState(false)
  const [hidePassword, setHidePassword] = useState(true)
  const [apiError, setApiError] = useState(false)
  const [captchaPassed, setCaptchaPassed] = useState(false)

  // This callback will only run if register was successful
  const registerCallback = () => {
    analyticsRegister()
    pixelCompleteRegistration()
    getCart()
    if (!process.env.GATSBY_SHOW_NUCLEO_INTRO || process.env.GATSBY_SHOW_NUCLEO_INTRO !== 'false') {
      navigate(`/intro/`)
    }
    else {
      navigate(`/my-account/`, {
        replace: true,
        state: { showNotice: "Your account has been registered" },
      })
    }
  }

  // Handle a register submission click
  const submitCallback = async (values) => {
    if (!captchaPassed) {
      return setApiError("Please click the Captcha checkbox before continuing.")
    } else {
      setApiError("")
    }

    // Only include company fields if business is selected
    let { company, vat_number, discount_code, ...modifiedValues } = values
    if (customerType == "business") {
      modifiedValues = values
    }
    performRegister(modifiedValues, setLoading, setApiError, registerCallback)
  }

  return (
    <RegisterPanelContainer>
      <BlurLoader loading={loading || loggedInState === null} message="Working">
        <RegisterFormSectionStyling>
          <StyledH2>Register</StyledH2>
          <OptionContainer>
            <OptionBox
              selected={customerType == "individual"}
              onClick={() => setCustomerType("individual")}
            >
              Individual
            </OptionBox>
            <OptionBox
              selected={customerType == "business"}
              onClick={() => setCustomerType("business")}
            >
              Business
            </OptionBox>
          </OptionContainer>
          <Formik
            initialValues={{
              first_name: "",
              last_name: "",
              email: "",
              phone: "",
              vat_number: "",
              discount_code: "",
              company: "",
              password: "",
              passwordConfirm: "",
            }}
            validate={validate}
            onSubmit={submitCallback}
          >
            {({ isSubmitting, errors, touched }) => (
              <>
                <Form>
                  <AccountsFormInputField
                    label="First Name"
                    type="text"
                    name="first_name"
                    autoComplete="given-name"
                    placeholder="Jane"
                    errors={errors}
                    touched={touched}
                  />
                  <AccountsFormInputField
                    label="Last Name"
                    type="text"
                    name="last_name"
                    autoComplete="family-name"
                    placeholder="Doe"
                    errors={errors}
                    touched={touched}
                  />
                  <AccountsFormInputField
                    label="Email"
                    type="email"
                    name="email"
                    autoComplete="email"
                    placeholder="jane@doe.com"
                    errors={errors}
                    touched={touched}
                  />
                  <AccountsFormInputField
                    label="Mobile Number"
                    type="phone"
                    name="phone"
                    autoComplete="phone"
                    placeholder="000 000 0000"
                    errors={errors}
                    touched={touched}
                  />
                  {customerType == "business" && (
                    <>
                      <AccountMetaFields />
                    </>
                  )}
                  <AccountsFormInputField
                    label="Password"
                    type={hidePassword ? "password" : "text"}
                    name="password"
                    autoComplete="new-password"
                    toggle={() => {
                      setHidePassword((prevState) => !prevState)
                    }}
                    errors={errors}
                    touched={touched}
                    hidePassword={hidePassword}
                  />
                  <AccountsFormInputField
                    label="Confirm Password"
                    type={hidePassword ? "password" : "text"}
                    name="passwordConfirm"
                    autoComplete="new-password"
                    toggle={() => {
                      setHidePassword((prevState) => !prevState)
                    }}
                    errors={errors}
                    touched={touched}
                    hidePassword={hidePassword}
                  />
                  <RecaptchaContainer>
                    <Reaptcha
                      sitekey={process.env.GATSBY_GOOGLE_CAPTCHA_SITEKEY}
                      onVerify={() => {
                        setCaptchaPassed(true)
                      }}
                      size="normal"
                    />
                  </RecaptchaContainer>
                  {apiError && <ErrorStyle>{apiError}</ErrorStyle>}
                  <StyledButton>
                    <button type="submit" disabled={loading}>
                      REGISTER
                    </button>
                  </StyledButton>
                </Form>
                <Extra>
                  Back to <LinkWrapper to="/login/">Login</LinkWrapper>
                </Extra>
              </>
            )}
          </Formik>
          <ResponsiveButton />
        </RegisterFormSectionStyling>
      </BlurLoader>
    </RegisterPanelContainer>
  )
}

// ===============
//     STYLES
// ===============
const ErrorStyle = styled.div`
  text-align: center;
  color: red;
`
const StyledH2 = styled.h2`
  padding-bottom: 20px;
`
const RegisterPanelContainer = styled.div`
  max-width: 350px;
  margin: 0 auto;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;

  .show-on {
    margin-top: 10px;
  }
`
const RegisterFormSectionStyling = styled.div`
  max-width: 500px;
  width: 100%;
  margin: 0 auto;
  .half-width-input input {
    display: block;
  }
  label {
    width: 100%;
    display: inline-block;
    margin-top: 10px;
  }
  .half-width-input {
    max-width: 400px;
    width: 100%;
    display: inline-block;
  }
  & form {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
  @media (max-width: ${({ theme }) => theme.breakMedium}) {
    .half-width-input {
      width: 48%;
    }
  }
  @media (max-width: ${({ theme }) => theme.breakSmall}) {
    .half-width-input {
      width: 100%;
    }
    & form > div {
      width: 100%;
    }
    & form > div button {
      width: 100%;
    }
  }
`
const OptionContainer = styled.div`
  display: flex;
  justify-content: space-between;
  div:first-child {
    margin-right: 10px;
  }
  div:last-child {
    margin-left: 10px;
  }
`
const OptionBox = styled.div`
  width: 100%;
  height: 50px;
  display: flex;
  align-items: center;
  place-content: center;
  cursor: pointer;
  color: ${({ selected, theme }) => (selected ? "white" : theme.colors.black1)};
  background-color: ${({ selected, theme }) =>
    selected ? theme.colors.brandPrimary : theme.colors.lightgrey3};
`
const RecaptchaContainer = styled.div`
  .g-recaptcha {
    display: flex;
    justify-content: center;
    margin: 20px 0;
  }
  @media (max-width: ${({ theme }) => theme.breakTiny}) {
    max-width: 270px;
    .g-recaptcha {
      justify-content: flex-start;
    }
  }
`
const StyledButton = styled.div`
  margin: 20px 0;
  width: 100%;
  button {
    width: 100%;
  }
`
const Extra = styled.div`
  color: ${({ theme }) => theme.colors.black1};
  text-align: center;
  margin: 20px 0;
  a {
    color: ${({ theme }) => theme.colors.black1};
    text-decoration: underline;
  }
`

// ===============
// 	   HELPERS
// ===============

const validate = (fields) => {
  const errors = {}
  const addIf = (pred, prop, message) => pred && (errors[prop] = message)

  addIf(!fields.first_name, "first_name", "First name is required")
  addIf(!fields.last_name, "last_name", "Last name is required")
  addIf(!fields.email, "email", "Email is required")
  addIf(!/\S+@\S+\.\S+/.test(fields.email), "email", "Email is invalid")
  addIf(!fields.phone, "phone", "Mobile number is required")
  addIf(
    !/^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/.test(
      fields.phone
    ),
    "phone",
    "Please use a 10 digit mobile number"
  )
  addIf(!fields.password, "password", "Password is required")
  addIf(!fields.passwordConfirm, "passwordConfirm", "Enter your password again")
  addIf(
    fields.password !== fields.passwordConfirm,
    "passwordConfirm",
    "Passwords do not match"
  )
  addIf(
    fields.password?.length < 6,
    "password",
    "Password should be at least 6 characters"
  )

  return errors
}

export default Register
